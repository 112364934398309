import { LoadingDialogService } from '../components/_loading-dialog/loading-dialog.service';
import { AlertDialogService } from '../components/_alert-dialog/alert-dialog.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

@Injectable()
export class ApplicationService {

  public infos = {
    name: '',
    version: ''
  };

  constructor(
    private _alert: AlertDialogService,
    private _http: HttpClient,
    private _load: LoadingDialogService,
    private _title: Title
  ) { }

  public checkForUpdates(): void {
    this._http.get("/toto/api/Connect/GetInfosApplication").subscribe(
      (result: any) => {
        if (result != null) {
          if (this.infos.version != result.version && this.infos.version != '') {
            this._alert.openDialog(
              'Mise à jour - ' + result.version,
              {message: 'Une nouvelle version de l\'application est disponible, veuillez procéder à la mise à jour.'},
              'none',
              [
                {
                  text: 'Mettre à jour',
                  type: 'primary',
                  callBack: () => {
                    this._load.start('Mise à jour en cours ...');
                    this.infos = result;
                    window.location.href = window.location.href;
                  }
                },
                {
                  text: 'Annuler',
                  type: 'warn',
                  callBack: () => {
                    return;
                  }
                },
              ]
            );
          } else {
            this.infos = result;
          }
          this._title.setTitle(result.name + ' ' + result.version);
        } 
      },
      (error) => {
        console.log(error);
      }
    );
  }

}
