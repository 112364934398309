<div
  class="h-dvh w-full bg-gradient-to-b from-[var(--primary)] to-[var(--main)] overflow-auto"
>
  <div class="relative w-full max-w-4xl mx-auto p-4 lg:px-6 lg:py-28">
    <h1 class="text-xl lg:text-4xl text-[var(--primary-color)] font-semibold">
      A quelle base souhaitez-vous vous connecter ?
    </h1>
    <h2
      class="text-[var(--primary-color)] lg:text-xl font-normal opacity-50 mb-8"
    >
      Choisissez parmis la liste suivante la base à laquelle vous voulez vous
      connecter.
    </h2>

    <div class="relative mb-8">
      <div
        class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
      >
        <mat-icon>search</mat-icon>
      </div>
      <input
        type="search"
        id="search"
        class="block w-full p-4 ps-10 text-sm text-[var(--text)] border border-[var(--border)] rounded-full bg-[var(--main)] focus:ring-transparent"
        placeholder="Rechercher..."
        name="searchSoc"
        [(ngModel)]="sSearch"
      />
    </div>

    <div class="grid gap-4 lg:grid-cols-2">
      <div
        *ngFor="let b of bases | filterBase : sSearch"
        class="w-full bg-[var(--main)] rounded-3xl shadow-2xl overflow-hidden"
      >
        <div class="h-52 w-full relative" [ngStyle]="$any(setImageAgence(b))">
          <div
            class="absolute -bottom-10 left-6 h-20 bg-[var(--main)] z-10 rounded-xl overflow-hidden shadow"
          >
            <img
              src="/img/logos/{{ b.base_Logo }}"
              alt=""
              class="h-full object-cover"
            />
          </div>
        </div>
        <div class="p-6 mt-10">
          <h3 class="text-[var(--text)] text-xl font-semibold mb-4">
            {{ b.base_Path }}
          </h3>
          <button
            mat-stroked-button
            (click)="connect(b)"
            class="w-full"
            style="height: 48px"
          >
            Se connecter <mat-icon iconPositionEnd>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
