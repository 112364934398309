import { AlertDialogService } from './../../components/_alert-dialog/alert-dialog.service';
import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'bottom-sheet',
  templateUrl: 'bottom-sheet.component.html',
})
export class BottomSheetComponent {

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    private _alert: AlertDialogService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  logOut(): void {
    this._alert.openDialog(
      'Confirmation',
      { message: 'Etes-vous sûr de vouloir vous déconnecter ?' },
      'confirm',
      [
        {
          text: 'Oui',
          type: 'primary',
          callBack: () => {
            this.logOutProcess();
          }
        },
        {
          text: 'Non',
          type: 'warn',
          callBack: () => { }
        },
      ]
    );
  }

  logOutProcess(): void {
    sessionStorage.clear();
    localStorage.removeItem('Gesteam_Password');
    this._router.navigateByUrl(this._route.snapshot.paramMap.get('Base'));
  }

}
