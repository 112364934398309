<!--<mat-sidenav-container class="main-sidenav-container" (backdropClick)="opened = !opened" [@routeAnimation]="o.isActivated ? o.activatedRoute : ''">-->
<mat-sidenav-container
  class="main-sidenav-container"
  (backdropClick)="opened = !opened"
>
  <mat-sidenav
    #sidenav="matSidenav"
    class="sidebar-navigation dark-background border-right border-color"
  >
    <ng-scrollbar class="menu-scrollbar">
      <div class="sidenav-container">
        <div class="main-title darkMode">
          <img src="/img/logo.png" alt="" class="icon-planning" />
          <span class="toolbar-spacer"></span>
          &nbsp;{{ societe }}&nbsp;
          <span class="toolbar-spacer"></span>
        </div>

        <div class="darkMode profil-zone">
          <div class="avatar-profil second-background">
            <div class="letters-zone important-title">
              {{ oLogUser.login_Nom.charAt(0)
              }}{{ oLogUser.login_Prenom.charAt(0) }}
              <img
                *ngIf="
                  oLogUser.login_Photo != '' && oLogUser.login_Photo != null
                "
                [src]="_tools.getPhotoPath(oLogUser.login_Photo)"
                onerror="this.onerror=null; this.remove();"
              />
            </div>
          </div>
          <div>
            Bienvenue,
            <div class="profil-name darker-txt">
              {{ oLogUser.login_Nom }} {{ oLogUser.login_Prenom }}
            </div>
          </div>
        </div>
        <mat-nav-list class="menu-list">
          <div class="spacer-24"></div>

          <ng-container *ngFor="let m of lMenu">
            <div
              mat-subheader
              class="menu-title"
              *ngIf="oLogUser.droits['RESPONSABLE'] == 1"
            >
              <div class="title txt-primary-300">{{ m.title }}</div>
              <div class="subtitle">{{ m.subtitle }}</div>
            </div>
            <ng-container *ngFor="let l of m.links">
              <mat-list-item
                *ngIf="showMenuLink(l)"
                [routerLink]="m.path + '/' + l.path"
                [routerLinkActive]="['active']"
                class="darkMode"
              >
                <div class="flex align-center full-width">
                  <div
                    class="full-width fw-500 dark-main-txt"
                    style="font-size: 14px"
                  >
                    {{ l.title }}
                  </div>
                  <span class="toolbar-spacer"></span>
                  <span
                    *ngIf="l.bNew"
                    class="alert-sm alert-info popup-animation"
                    >New</span
                  >
                  <ng-container
                    *ngIf="
                      l.path == 'incidents' && _notifs.getNewIncidents()
                        | async as incidents
                    "
                  >
                    <span
                      *ngIf="incidents.length > 0"
                      class="alert-lg alert-danger popup-animation"
                      >{{ incidents.length }}</span
                    >
                  </ng-container>
                  <ng-container
                    *ngIf="l.path == 'documents' && _notifs.getNewDocuments()"
                  >
                    <span
                      *ngIf="_notifs.getNewDocuments().length > 0"
                      class="alert-lg alert-danger popup-animation"
                      >{{ _notifs.getNewDocuments().length }}</span
                    >
                  </ng-container>
                </div>
                <mat-icon
                  matListItemIcon
                  class="material-icons-outlined darker-txt darkMode"
                  >{{ l.icon }}</mat-icon
                >
              </mat-list-item>
            </ng-container>
            <div
              class="spacer-24"
              *ngIf="oLogUser.droits['RESPONSABLE'] == 1"
            ></div>
          </ng-container>
        </mat-nav-list>

        <div class="menu-footer border-top border-color">
          &copy;
          <a href="https://hpdg.fr/" class="txt-primary-300" target="_blank"
            >HPDG</a
          ><br />
          <span>Version {{ _app.infos.version }}</span>
        </div>
      </div>
    </ng-scrollbar>
  </mat-sidenav>

  <mat-toolbar class="main-toolbar hidden-small border-bottom border-color">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <span class="toolbar-spacer"></span>
    <!--<button mat-icon-button (click)="toggleModal('slideInFromLeft')">
      <mat-icon>notifications</mat-icon>
    </button>
    <button mat-icon-button (click)="startTour()">
      <mat-icon>help</mat-icon>
    </button>-->
    <button mat-icon-button (click)="logOut()" title="Déconnexion">
      <mat-icon>power_settings_new</mat-icon>
    </button>
  </mat-toolbar>
  <div
    class="main-container"
    [@routeAnimation]="o.isActivated ? o.activatedRoute : ''"
  >
    <router-outlet #o="outlet"></router-outlet>
    <!--<div *ngIf="true" class="main-content">
      <mat-card>
        <div class="flex">
          <div class="flex-1 error-zone">
            <div class="title">Erreur</div>
            <div class="subtitle">Une erreur est survenu lors de l'appel d'un WebService, voici les détails :</div>
          </div>
          <div class="flex-1">2</div>
        </div>
      </mat-card>
    </div>-->
  </div>
  <mat-toolbar
    class="main-background reverse main-toolbar-bottom mat-elevation-z8 hidden-big"
  >
    <div class="flex align-center justify-between full-width">
      <ng-container *ngFor="let m of lMenu">
        <div
          class="link"
          *ngIf="
            (m.responsable && oLogUser.droits['RESPONSABLE'] == 1) ||
            !m.responsable
          "
          [routerLink]="[m.path]"
          [routerLinkActive]="['active']"
        >
          <mat-icon class="material-icons-outlined dark-main-txt">{{
            m.icon
          }}</mat-icon>
          <div>{{ m.title }}</div>
        </div>
      </ng-container>
    </div>
  </mat-toolbar>
</mat-sidenav-container>

<div class="floating-process">
  <mat-card *ngIf="_compteurs.bShowLoad">
    <mat-card-content class="flex align-center direction-row gap-8 darker-txt">
      <ng-container *ngIf="!_compteurs.bFinishLoad">
        <div>
          <mat-spinner [diameter]="20" color="primary"></mat-spinner>
        </div>
        {{ _compteurs.sMessage }}
        <button mat-button color="warn" (click)="_compteurs.cancelRequest()">
          Annuler
        </button>
      </ng-container>

      <ng-container *ngIf="_compteurs.bFinishLoad">
        <div>
          <mat-icon class="txt-success">check</mat-icon>
        </div>
        Processus terminé !
        <button
          mat-button
          color="primary"
          [routerLink]="'statistic/compteurs'"
          (click)="_compteurs.cancelRequest()"
        >
          Afficher
        </button>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
