<div mat-dialog-content class="relative">
  <div class="dialog-flex">
    <div class="dialog-icon" *ngIf="data.dialogType != 'none'">
      <div class="ui-success" *ngIf="data.dialogType == 'success'">
        <svg
          viewBox="0 0 87 87"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="Group-3" transform="translate(2.000000, 2.000000)">
              <circle
                id="Oval-2"
                stroke="rgba(165, 220, 134, 0.2)"
                stroke-width="4"
                cx="41.5"
                cy="41.5"
                r="41.5"
              ></circle>
              <circle
                class="ui-success-circle"
                id="Oval-2"
                stroke="#A5DC86"
                stroke-width="4"
                cx="41.5"
                cy="41.5"
                r="41.5"
              ></circle>
              <polyline
                class="ui-success-path"
                id="Path-2"
                stroke="#A5DC86"
                stroke-width="4"
                points="19 38.8036813 31.1020744 54.8046875 63.299221 28"
              ></polyline>
            </g>
          </g>
        </svg>
      </div>
      <div class="ui-error" *ngIf="data.dialogType == 'error'">
        <svg
          viewBox="0 0 87 87"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="Group-2" transform="translate(2.000000, 2.000000)">
              <circle
                id="Oval-2"
                stroke="rgba(252, 191, 191, .5)"
                stroke-width="4"
                cx="41.5"
                cy="41.5"
                r="41.5"
              ></circle>
              <circle
                class="ui-error-circle"
                stroke="#F74444"
                stroke-width="4"
                cx="41.5"
                cy="41.5"
                r="41.5"
              ></circle>
              <path
                class="ui-error-line1"
                d="M22.244224,22 L60.4279902,60.1837662"
                id="Line"
                stroke="#F74444"
                stroke-width="3"
                stroke-linecap="square"
              ></path>
              <path
                class="ui-error-line2"
                d="M60.755776,21 L23.244224,59.8443492"
                id="Line"
                stroke="#F74444"
                stroke-width="3"
                stroke-linecap="square"
              ></path>
            </g>
          </g>
        </svg>
      </div>
      <div class="ui-confirm" *ngIf="data.dialogType == 'confirm'">
        <svg viewBox="94.43 77.382 313.003 255.509">
          <ellipse cx="168.146" cy="328.351" rx="10.903" ry="4.089" class="fill-dark"></ellipse>
          <ellipse cx="194.314" cy="327.806" rx="10.903" ry="4.089" class="fill-dark"></ellipse>
          <ellipse cx="469.744" cy="542.424" rx="21.534" ry="6.76" transform="matrix(0.866352, -0.499434, 0.499434, 0.866352, -452.192627, 21.990744)" class="fill-dark"></ellipse>
          <circle cx="420.936" cy="555.946" r="43.067" transform="matrix(0.160182, -0.987087, 0.987087, 0.160182, -439.325806, 597.285522)" class="fill-dark"></circle>
          <rect x="157.243" y="304.637" width="13.084" height="23.442" class="fill-dark"></rect>
          <rect x="183.411" y="304.637" width="13.084" height="23.442" class="fill-dark"></rect>
          <circle cx="177.959" cy="259.934" r="14.719" class="fill-light"></circle>
          <circle cx="177.959" cy="259.934" r="4.906" class="fill-dark-light"></circle>
          <path d="M 135.092 230.786 C 131.614 215.212 142.731 199.475 159.921 195.637 C 177.11 191.798 193.865 201.312 197.342 216.886 C 200.82 232.459 189.427 238.203 172.237 242.042 C 155.047 245.88 138.569 246.359 135.092 230.786 Z" class="fill-primary-50"></path>
          <ellipse cx="372.428" cy="535.963" rx="6.76" ry="21.534" transform="matrix(0.428529, -0.903528, 0.903528, 0.428529, -515.493286, 357.677124)" class="fill-dark"></ellipse>
          <path d="M 155.714 286.374 C 155.714 290.589 166.567 298.913 178.611 298.913 C 190.654 298.913 201.946 287.046 201.946 282.83 C 201.946 278.615 190.654 283.648 178.611 283.648 C 166.567 283.648 155.714 282.159 155.714 286.374 Z" class="fill-light"></path>
          <ellipse cx="327.041" cy="328.351" rx="10.903" ry="4.089" class="fill-dark"></ellipse>
          <ellipse cx="300.874" cy="327.806" rx="10.903" ry="4.089" class="fill-dark"></ellipse>
          <ellipse cx="513.579" cy="542.424" rx="6.76" ry="21.534" transform="matrix(0.499434, -0.866352, 0.866352, 0.499434, -456.917847, 431.349976)" class="fill-dark"></ellipse>
          <circle cx="318.319" cy="270.837" r="43.067" class="fill-dark"></circle>
          <rect x="324.86" y="304.637" width="13.084" height="23.442" class="fill-dark"></rect>
          <rect x="298.693" y="304.637" width="13.084" height="23.442" class="fill-dark"></rect>
          <circle cx="308.228" cy="259.934" r="14.719" class="fill-light"></circle>
          <ellipse cx="545.296" cy="543.043" rx="4.921" ry="4.886" transform="matrix(0.707424, -0.706789, 0.706789, 0.707424, -468.344696, 259.181213)" class="fill-dark-light"></ellipse>
          <path d="M 322.95 242.042 C 305.76 238.203 294.367 232.459 297.845 216.886 C 301.322 201.312 318.077 191.798 335.267 195.637 C 352.457 199.475 363.573 215.212 360.095 230.786 C 356.618 246.359 340.14 245.88 322.95 242.042 Z" class="fill-primary-400"></path>
          <ellipse cx="601.895" cy="567.963" rx="6.76" ry="21.534" transform="matrix(0.96649, -0.256704, 0.256704, 0.96649, -369.696838, -111.568008)" class="fill-dark"></ellipse>
          <path d="M 308.562 283.649 C 300.655 283.649 293.241 278.616 293.241 282.831 C 293.241 287.046 300.655 298.913 308.562 298.913 C 316.468 298.913 323.594 290.59 323.594 286.375 C 323.594 282.159 316.468 283.649 308.562 283.649 Z" class="fill-light"></path>
          <path d="M 252.904 199.43 L 249.079 199.43 C 241.945 199.422 236.164 193.641 236.156 186.507 L 236.156 183.296 C 236.156 175.343 237.663 168.684 240.635 163.503 C 244.237 157.478 248.439 151.832 253.177 146.652 C 256.323 143.489 259.177 140.049 261.705 136.373 C 263.519 133.518 264.456 130.193 264.399 126.811 C 264.399 118.833 259.201 114.954 248.508 114.954 C 242.576 115.021 236.683 115.906 230.993 117.583 C 221.574 120.272 212.201 113.199 212.2 103.405 L 212.2 96.905 C 212.175 91.188 215.519 85.993 220.733 83.648 C 230.781 79.36 241.618 77.228 252.543 77.391 C 263.987 77.391 274.318 79.313 283.25 83.104 C 292.197 86.903 299.267 92.26 304.261 99.027 C 309.24 105.747 311.886 113.909 311.797 122.272 C 311.797 131.252 309.345 139.073 304.51 145.518 C 299.728 151.898 292.873 158.882 284.134 166.278 C 279.53 170.107 275.167 174.217 271.071 178.585 C 268.425 181.435 266.555 184.917 265.64 188.696 C 264.587 194.905 259.201 199.444 252.904 199.43 Z" class="fill-primary-400"></path>
          <path d="M 250.777 260.201 L 250.273 260.201 C 231.835 260.189 220.325 240.223 229.554 224.261 C 233.832 216.863 241.727 212.304 250.273 212.299 L 250.777 212.299 C 269.215 212.287 280.751 232.239 271.543 248.212 C 267.264 255.635 259.345 260.207 250.777 260.201 Z" class="fill-primary-400"></path>
          <circle cx="155.653" cy="157.061" r="6.467" fill="#f2f2f2"></circle>
        </svg>
      </div>
      <!--<div class="ui-img" *ngIf="data.dialogType != 'success' && data.dialogType != 'error'">
        <img src="/svg/{{data.dialogType}}" alt="" srcset="">
      </div>-->
      <div class="ui-warning" *ngIf="data.dialogType == 'warning'">
        <mat-icon class="txt-warning">warning</mat-icon>
      </div>
    </div>
    <div class="dialog-message">
      <h4 class="important-title dark-txt">{{data.dialogTitle}}</h4>
      <mat-divider></mat-divider><br />
      <p>{{data.dialogMessage.message}}</p>
      <ng-container *ngIf="data.dialogMessage.errors">
        <ul>
          <li *ngFor="let e of data.dialogMessage.errors">{{e}}</li>
        </ul>
      </ng-container>
      <br />
    </div>
  </div>
</div>
<div
  mat-dialog-actions
  align="end"
  class="dialog-footer-buttons third-background"
>
  <ng-container *ngIf="data.dialogButtons.length == 0">
    <button mat-stroked-button color="primary" mat-dialog-close cdkFocusInitial>
      Ok
    </button>
  </ng-container>
  <ng-container *ngIf="data.dialogButtons.length > 0">
    <button
      *ngFor="let b of data.dialogButtons"
      mat-stroked-button
      [color]="b.type"
      mat-dialog-close
      (click)="emitFunction(b.callBack)"
    >
      {{b.text}}
    </button>
  </ng-container>
</div>
