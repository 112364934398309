import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog } from './dialog';

@Component({
  selector: 'alert-dialog',
  templateUrl: 'alert-dialog.html',
  styleUrls: ['alert-dialog.scss']
})
export class AlertDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Dialog) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  emitFunction(f: Function) {
    this.dialogRef.close();
    f();
  }

}
