import { Component, OnDestroy } from "@angular/core";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";

@Component({
  selector: "app-spinner",
  template: `
    <div
      class="h-dvh w-full flex items-center justify-center bg-[var(--dark-main)] fixed z-50"
      *ngIf="isSpinnerVisible"
    >
      <div class="relative size-10">
        <div class="double-bounce1 bg-[var(--primary)]"></div>
        <div class="double-bounce2 bg-[var(--primary)]"></div>
      </div>
    </div>
  `,
  styleUrls: ["spinner.component.scss"],
  standalone: false,
})
export class SpinnerComponent implements OnDestroy {
  public isSpinnerVisible = true;

  constructor(private router: Router) {
    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          //this.isSpinnerVisible = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.hideSpinner();
        }
      },
      () => {
        this.hideSpinner();
      }
    );
  }

  ngOnDestroy(): void {
    this.hideSpinner();
  }

  hideSpinner(): void {
    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  }
}
