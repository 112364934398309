import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'loading-dialog',
  templateUrl: './loading.dialog.html'
})

export class LoadingDialog {

  static dialog: any;

  static close() {
    this.dialog.close();
  }

  constructor(
    public dialogRef: MatDialogRef<LoadingDialog>,
    @Inject(MAT_DIALOG_DATA) public data: string) {

    LoadingDialog.dialog = dialogRef;
  }

}
