import { AlertDialogService } from './../components/_alert-dialog/alert-dialog.service';
import { HttpError } from './ManageHttpInterceptor';
import { Injectable } from '@angular/core';
import { ModalService } from '../components/_modal';

@Injectable()
export class ToolsService {

  private days = [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi'
  ];

  private daysShort = [
    'Dim',
    'Lun',
    'Mar',
    'Mer',
    'Jeu',
    'Ven',
    'Sam'
  ];

  private months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ];

  constructor(
    private _modal: ModalService,
    private _alert: AlertDialogService
  ) { }

  isMobileSupport(): boolean {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }

  dateUTC(d: Date): Date {
    //return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
    return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0));
  }

  getAbs(value: number) {
    return Math.abs(value);
  }

  dateToString(date: Date): string {
    if (date !== null) {
      const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      const mm = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
      const yyyy = date.getFullYear();
      return dd + '/' + mm + '/' + yyyy;
    } else {
      return null;
    }
  }

  timeToString(date: Date): string {
    if (date !== null) {
      const hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
      const mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      return hh + ':' + mm;
    } else {
      return null;
    }
  }

  showHttpError(error: HttpError): void {
    const list = [];
    list.push('Name : ' + error.name);
    list.push('Status : ' + error.status);
    list.push('Url : ' + error.url);
    list.push('Message : ' + error.message);
    this._alert.openDialog(
      'Erreur',
      {
        message: 'Une erreur est survenue, vous trouverez plus d\'informations ci-dessous, ainsi que dans la console :',
        errors: list
      },
      'error'
    );
  }

  getFirstDayOfWeek(d: Date, start: number = 1): Date {
    const day = d.getDay();
    const diff = d.getDate() - day + (day == 0 ? -6:start);
    return new Date(d.setDate(diff));
  }

  addDays(d: Date, days: number) {
    const date = d;
    return new Date(date.setDate(date.getDate() + days));
  }

  removeDays(d: Date, days: number) {
    const date = d;
    return new Date(date.setDate(date.getDate() - days));
  }

  getWeekNumber(d: Date): number {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    const weekNo = Math.ceil(( ( (d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
    return weekNo;
  }

  getWeekNumbers(start: Date, end: Date): number[] {
    let weeks = [];
    let currentDate = this.getFirstDayOfWeek(start, 1);
    while (currentDate <= end) {
      weeks.push(this.getWeekNumber(currentDate));
      currentDate = this.addDays(currentDate, 7);
    }
    return weeks;
  }

  getStringDay(d: string): string {
    const s = new Date(parseInt(d));
    return this.days[s.getDay()];
  }

  getStringDayShort(d: string): string {
    const s = new Date(parseInt(d));
    return this.daysShort[s.getDay()];
  }

  getStringMonth(d: string): string {
    const s = new Date(d);
    return this.months[s.getMonth()];
  }

  checkUpdateSelect(checkList: string[], changes: any): boolean {
    let updateData = false;
    checkList.forEach(cl => {
      if (changes[cl]) {
        if (changes[cl].previousValue != changes[cl].currentValue) {
          updateData = true;
        }
      }
    });
    return updateData;
  }

  getDaysList(): string[] {
    return this.days;
  }

  getPhotoPath(p: string) {
    return '/' + localStorage.getItem('Gesteam_Base') + '/photo/' + p;
  }

  round(number: number, precision: number): number {
    return parseFloat(number.toFixed(precision));
  }

}
