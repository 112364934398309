<div class="loading" *ngIf="bLoad">
  <mat-spinner></mat-spinner>
</div>

<div mat-dialog-content *ngIf="!bLoad">
  <h4>Modification du mot de passe</h4>
  <mat-divider></mat-divider><br/>
  <p *ngIf="!oLogUser.login_Connect">
    Votre compte possède actuellement un mot de passe temporaire, pour des raisons de sécurité, veuillez renseigner un nouveau mot de passe
  </p>
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Ancien mot de passe</mat-label>
    <input matInput maxlength="50" [(ngModel)]="sOldPassword" [type]="hide1 ? 'password' : 'text'">
    <mat-icon matSuffix (click)="hide1 = !hide1" class="pointer">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
  </mat-form-field>
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Nouveau mot de passe</mat-label>
    <input matInput maxlength="50" [(ngModel)]="sNewPassword" [type]="hide2 ? 'password' : 'text'">
    <mat-icon matSuffix (click)="hide2 = !hide2" class="pointer">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
  </mat-form-field>
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Confirmation</mat-label>
    <input matInput maxlength="50" [(ngModel)]="sConfirm" [type]="hide3 ? 'password' : 'text'">
    <mat-icon matSuffix (click)="hide3 = !hide3" class="pointer">{{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end" class="dialog-footer-buttons third-background">
  <button color="primary" mat-stroked-button (click)="onClick()">Valider</button>
  <button color="warn" mat-stroked-button (click)="onNoClick()" cdkFocusInitial>Annuler</button>
</div>
