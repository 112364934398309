import { NgScrollbarModule } from 'ngx-scrollbar';
import { ApplicationService } from './services/application.service';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRouting } from './app-routing';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PagesComponent } from './pages/pages.component';
import { MaterialModule } from './material-module';
import { ModalModule } from './components/_modal';
import { ToolsService } from './services/tools.service';
import { NotfoundComponent } from './notfound/notfound.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { AlertDialogService } from './components/_alert-dialog/alert-dialog.service';
import { CommonModule } from '@angular/common';
import { ForgotDialog } from './login/forgot-dialog/forgot-dialog';
import { FilterBasePipe, MainComponent } from './main/main.component';
import { ToastrModule } from 'ngx-toastr';
import { SvgProfilModule } from './core/svg/svg-profil/svg-profil.module';
import { SpinnerModule } from './core/spinner/spinner.module';
import { BottomSheetComponent } from './core/bottom-sheet/bottom-sheet.component';
import { FormsModule } from '@angular/forms';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PagesComponent,
    NotfoundComponent,
    ForgotDialog,
    MainComponent,
    FilterBasePipe
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRouting,
    SpinnerModule,
    BrowserAnimationsModule,
    MaterialModule,
    ModalModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    ToastrModule.forRoot({
      positionClass :'toast-top-right',
      closeButton: true,
      progressBar: true,
      timeOut: 7000
    }),
    NgxMatSelectSearchModule,
    SvgProfilModule,
    NgScrollbarModule
  ],
  providers: [
    AlertDialogService,
    ToolsService,
    ApplicationService,
    BottomSheetComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
