import { trigger, keyframes, animate, transition, style, query, group, animateChild, state } from '@angular/animations';

export let slideInAnimation = trigger('routeAnimations', [
  transition('* => slideInFromLeft', animate(500, keyframes([
    style({ transform: 'translate3d(100%, 0, 0)'}),
    style({ transform: 'translate3d(0, 0, 0)' }),
  ]))),
  transition('* => slideOutToRight', animate(500, keyframes([
    style({ transform: 'translate3d(0, 0, 0)' }),
    style({ transform: 'translate3d(100%, 0, 0)' }),
  ]))),
  transition('* => fallDown', animate(500, keyframes([
    style({ transform: 'translate3d(0, -100%, 0)' }),
    style({ transform: 'translate3d(0, 0, 0)' }),
  ]))),
  transition('* => jumpUp', animate(500, keyframes([
    style({ transform: 'translate3d(0, 0, 0)' }),
    style({ transform: 'translate3d(0, -100%, 0)' }),
  ]))),
]);

export const routeAnimation = trigger('routeAnimation', [
  transition('* => *', [
    query(':enter, :leave',
      style({ position: 'absolute',  width: '100%' }),
      { optional: true }),
    group([
      query(':enter', [
        style({ opacity: 0}),
        animate('.5s .3s ease-in-out',
        style({ opacity: 1 }))
      ], { optional: true }),
      query(':leave', [
        style({ opacity: 1 }),
        animate('.5s ease-in-out',
        style({ opacity: 0 }))
        ], { optional: true }),
    ])
  ])
]);

export const routeAppAnimation = trigger('routeAppAnimation', [
  transition('* => *', [
    query(':enter, :leave',
      style({ position: 'fixed',  width: '100%' }),
      { optional: true }),
    group([
      query(':enter', [
        style({ 'clip-path': 'circle(0%)' }),
        animate('1s .7s ease-in-out',
        style({ 'clip-path': 'circle(100%)' })),
      ], { optional: true }),
      query(':leave', [
        style({ 'clip-path': 'circle(100%)' }),
        animate('.5s ease-in-out',
        style({ 'clip-path': 'circle(0%)' }))
        ], { optional: true }),
    ])
  ])
]);
