<div class="relative h-dvh w-full overflow-auto" [ngStyle]="$any(setImageLogin())">
  <div class="fixed top-0 left-0 size-full bg-white/60 dark:bg-black/60"></div>
  <div
    class="relative w-full max-w-screen-2xl mx-auto flex flex-col lg:flex-row items-center gap-8 p-4 lg:px-6 lg:py-28">
    <div class="flex-1">
      <div class="relative inline-block h-14 lg:h-20 rounded-xl overflow-hidden mb-4 shadow bg-[var(--main)]">
        <img *ngIf="logo != '' && logo != null" src="/img/logos/{{ logo }}" alt="" class="h-full object-cover" />
        <img *ngIf="logo == '' || logo == null" src="/img/logo.png" alt="" class="h-full object-cover" />
      </div>

      <h1 class="text-[var(--text)] font-semibold text-xl lg:text-4xl">
        Bienvenue sur votre espace
        <span class="font-black">{{ _app.infos.name }}
          <span class="text-[var(--primary)]">{{ societe }}</span></span>,
      </h1>
      <h2 class="text-[var(--text-light)] lg:text-xl font-normal">
        Connectez-vous via le formulaire suivant pour accéder à votre espace
        personnel.
      </h2>
    </div>
    <div class="relative w-full max-w-screen-sm bg-[var(--main)] p-6 lg:p-20 rounded-3xl shadow-2xl overflow-hidden">
      <div *ngIf="bConnect"
        class="z-10 absolute top-0 left-0 bg-[var(--main)] size-full flex items-center justify-center">
        <div class="relative size-10">
          <div class="double-bounce1 bg-[var(--primary)]"></div>
          <div class="double-bounce2 bg-[var(--primary)]"></div>
        </div>
      </div>

      <h1 class="text-center font-semibold text-2xl text-[var(--text)] mb-8">
        Se connecter
      </h1>

      <form class="w-full" #dataForm="ngForm" [formGroup]="loginForm" (ngSubmit)="connect(loginForm)">
        <div class="flex flex-col">
          <label class="text-[var(--text-light)]">Adresse mail <span class="text-[var(--error)]">*</span></label>
          <mat-form-field appearance="outline">
            <input matInput type="email" formControlName="user" required name="gesteam-login" />
            <mat-icon matSuffix>alternate_email</mat-icon>
            <mat-error *ngIf="hasError(loginForm, 'user', 'required')">Adresse mail obligatoire</mat-error>
          </mat-form-field>
        </div>

        <div class="flex flex-col">
          <label class="text-[var(--text-light)]">Mot de passe <span class="text-[var(--error)]">*</span></label>
          <mat-form-field appearance="outline">
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required
              name="gesteam-password" />
            <mat-icon matSuffix (click)="hide = !hide" class="cursor-pointer">{{
              hide ? "visibility_off" : "visibility"
              }}</mat-icon>
            <mat-error *ngIf="hasError(loginForm, 'password', 'required')">Mot de passe obligatoire</mat-error>
          </mat-form-field>

          <mat-checkbox formControlName="remember" color="primary" class="mb-4">Rester
            connecté</mat-checkbox>

          <button class="btn btn-primary" style="height: 48px">
            <mat-icon>login</mat-icon>
            Se connecter
          </button>

          <div class="flex items-center flex-col my-4">
            <a (click)="openForgotDialog()" class="text-[var(--primary)] hover:underline cursor-pointer">Mot de passe
              oublié ?</a>
          </div>

          <div class="flex items-center">
            <span class="border-b border-[var(--border)] flex-1"></span>
            <span class="p-4 text-[var(--text-lightest)]">Ou</span>
            <span class="border-b border-[var(--border)] flex-1"></span>
          </div>

          <button mat-stroked-button color="primary" (click)="changeBase()" class="mt-4" type="button"
            style="min-height: 48px">
            <mat-icon>chevron_left</mat-icon>
            Changer de société
          </button>
        </div>
      </form>
    </div>
  </div>
</div>