<div class="login">
  <div class="login--background" [ngStyle]="$any(setImageLogin())">
    <div class="content darkMode">
      <div class="flex direction-column max-960">
        <h1 class="dark-txt">Bienvenue sur votre espace<br/><span style="font-size: 4rem;">{{_app.infos.name}} <span class="txt-primary important-title">{{societe}}</span></span>,</h1>
        <h4 class="dark-txt-light">Connectez-vous via le formulaire suivant pour accéder à votre espace personnel.</h4>
        <div class="version dark-txt-light">
          <div class="spacer-16"></div>
          &copy; <a href="https://hpdg.fr/" class="txt-primary" target="_blank">HPDG</a> - Version {{_app.infos.version}}
        </div>
      </div>
    </div>
  </div>
  <div class="login--zone main-background relative mat-elevation-z4">
    <div class="svg-background">
      <svg viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" stroke="currentColor" stroke-width="100" style="opacity: .5;">
          <circle r="234" cx="196" cy="23" [style]="theme == 'darkMode' ? 'color: rgba(255, 255, 255, 0.1);' : 'color: rgba(0, 0, 0, 0.1);'"></circle>
          <circle r="234" cx="790" cy="491" [style]="theme == 'darkMode' ? 'color: rgba(255, 255, 255, 0.1);' : 'color: rgba(0, 0, 0, 0.1);'"></circle>
        </g>
      </svg>
    </div>
    <div class="loading" *ngIf="bConnect">
      <div class="spinner">
        <div class="double-bounce1 primary-background"></div>
        <div class="double-bounce2 primary-background"></div>
      </div>
    </div>

    <form class="full-width" #dataForm="ngForm" [formGroup]="loginForm" (ngSubmit)="connect(loginForm)" *ngIf="!bConnect">
      <img *ngIf="logo != '' && logo != null" src="/img/logos/{{logo}}" alt="" class="icon-login">
      <img *ngIf="logo == '' || logo == null" src="/img/logo.png" alt="" class="icon-login">
      <h2 class="dark-txt important-title">Se connecter</h2>

      <div class="spacer-32"></div>

      <div class="flex direction-column">
        <label>Adresse mail <span class="txt-danger">*</span></label>
        <mat-form-field appearance="outline" class="full-width">
          <input matInput type="email" formControlName="user" required name="gesteam-login">
          <mat-icon matSuffix>alternate_email</mat-icon>
          <mat-error *ngIf="hasError(loginForm, 'user', 'required')">Adresse mail obligatoire</mat-error>
        </mat-form-field>
      </div>

      <div class="flex direction-column">
        <label>Mot de passe <span class="txt-danger">*</span></label>
        <mat-form-field appearance="outline" class="full-width">
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required name="gesteam-password">
          <mat-icon matSuffix (click)="hide = !hide" class="pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="hasError(loginForm, 'password', 'required')">Mot de passe obligatoire</mat-error>
        </mat-form-field>
      </div>

      <div class="flex align-center direction-column">
        <mat-checkbox formControl_app.infos.name="remember" color="primary">Rester connecté</mat-checkbox>
        <a (click)="openForgotDialog()" class="txt-primary pointer">Mot de passe oublié ?</a>
      </div>

      <div class="spacer-32"></div>

      <button mat-raised-button color="primary" class="full-width" style="min-height: 48px;">
        <mat-icon>login</mat-icon>
        Se connecter
      </button>

      <div class="spacer-32"></div>

      <div class="flex align-center">
        <span class="border-all border-color flex-1"></span>
        <span class="margin-8">Ou</span>
        <span class="border-all border-color flex-1"></span>
      </div>

      <div class="spacer-32"></div>

      <button mat-stroked-button color="primary" (click)="changeBase()" class="full-width" type="button">
        <mat-icon>chevron_left</mat-icon>
        Changer de société
      </button>
    </form>
  </div>
</div>
