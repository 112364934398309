export const menu = [
  {
    title: 'Planification',
    subtitle: 'Organisation et gestion du planning',
    path: 'planification',
    icon: 'event',
    links: [
      {
        title: 'Planning',
        icon: 'event',
        path: 'planning',
        bNew: false
      },
      {
        title: 'Incidents',
        icon: 'event_busy',
        path: 'incidents',
        validationIncident: true,
        bNew: false
      }
    ]
  },
  {
    title: 'Paie',
    subtitle: 'Organisation et gestion de la paie',
    path: 'pay',
    icon: 'euro',
    responsable: true,
    links: [
      {
        title: 'Gestion des régules de pointage',
        icon: 'edit_calendar',
        path: 'reg-pointage',
        responsable: true,
        bNew: false
      },
      {
        title: 'Gestion des régules de prime',
        icon: 'price_check',
        path: 'reg-prime',
        responsable: true,
        bNew: false
      },
      {
        title: 'Régules de pointage (paie)',
        icon: 'request_quote',
        path: 'reg-pointage-paie',
        responsable: true,
        bNew: false
      },
    ]
  },
  {
    title: 'Statistiques',
    subtitle: 'Extraction et récapitulatifs des données',
    path: 'statistic',
    icon: 'bar_chart',
    responsable: false,
    links: [
      {
        title: 'Récap des compteurs',
        icon: 'query_stats',
        path: 'compteurs',
        responsable: false,
        bNew: false
      },
      {
        title: 'Récap des primes',
        icon: 'table_chart',
        path: 'recap-prime',
        responsable: true,
        bNew: false
      }
    ]
  },
  {
    title: 'Divers',
    subtitle: 'Fiches diverses',
    path: 'divers',
    icon: 'settings',
    links: [
      {
        title: 'Salariés',
        icon: 'group',
        path: 'users',
        bNew: false,
        responsable: true
      },
      {
        title: 'Liste d\'éléments',
        icon: 'format_list_bulleted',
        path: 'elements',
        bNew: false,
        responsable: true
      },
      {
        title: 'Profil',
        icon: 'manage_accounts',
        path: 'profile',
        bNew: false
      },
      {
        title: 'Paramètres',
        icon: 'settings',
        path: 'parameters',
        bNew: false
      }
    ]
  }
];
