import { HttpClient } from '@angular/common/http';
import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlertDialogService } from '../../components/_alert-dialog/alert-dialog.service';

@Component({
  selector: 'forgot-dialog',
  templateUrl: './forgot-dialog.html'
})
export class ForgotDialog {

  public sEmail = '';
  public bLoad: boolean;

  constructor(
    public dialogRef: MatDialogRef<ForgotDialog>,
    private _alert: AlertDialogService,
    private _http: HttpClient,
    private _route: ActivatedRoute) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick(): void {
    if (this.sEmail != '') {
      this.bLoad = true;
      this._http.post<any>(localStorage.getItem('Gesteam_Base') + '/api/Connect/ResetPassword',
        {
          Email: this.sEmail
        }
      ).subscribe(result => {
        if (result.isOk) {
          this.onNoClick();
          this._alert.openDialog('Félicitation !', {message: 'Un email vous a été envoyé avec un nouveau mot de passe !'}, 'success');
        } else {
          this._alert.openDialog('Erreur', {message: result.message}, 'error');
          this.bLoad = false;
        }
      }, error => {
        console.error(error);
        this.bLoad = false;
      });
    } else {
      this._alert.openDialog('Erreur', {message: 'Veuillez renseigner votre adresse mail ...'}, 'error');
    }
  }

}
