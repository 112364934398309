import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialog } from './loading.dialog';

@Injectable({ providedIn: 'root' })
export class LoadingDialogService {

  constructor(private dialog: MatDialog) {}

  start(message: any): void {
    this.dialog.open(LoadingDialog, {
      disableClose: true,
      data: message
    });
  }

  end() {
    LoadingDialog.close();
  }

}
