import { BreakpointObserver } from "@angular/cdk/layout";
import {
  AfterViewInit,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ModalService } from "../components/_modal";
import { routeAnimation, slideInAnimation } from "../exports/animation";
import { menu } from "../exports/menu";
import { ApplicationService } from "../services/application.service";
import { AlertDialogService } from "./../components/_alert-dialog/alert-dialog.service";
import { PasswordDialog } from "./../components/_password-dialog/password-dialog";
import { ToolsService } from "./../services/tools.service";
//import { JoyrideService } from 'ngx-joyride';
import { MatSidenav } from "@angular/material/sidenav";
import { HttpService } from "../services/http.service";
import { NotificationService } from "../services/notification.service";
import { CompteursService } from "./statistic/compteurs/compteurs.service";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
  animations: [routeAnimation, slideInAnimation],
})
export class PagesComponent implements OnInit, AfterViewInit {
  public lMenu = menu;
  public oLogUser = JSON.parse(sessionStorage.getItem("Gesteam_User"));
  public mode = "over";
  public opened = false;
  public layoutGap = "64";
  public fixedInViewport = true;
  public modalAnimation: string;
  public isExpanded = true;
  public showSubmenu = false;
  public isShowing = false;
  public logo = localStorage.getItem("Gesteam_Logo");
  public societe = localStorage.getItem("Gesteam_Societe");

  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @HostBinding("class") className = "";

  public constructor(
    public _tools: ToolsService,
    public _app: ApplicationService,
    public _dialog: MatDialog,
    public _compteurs: CompteursService,
    public _notifs: NotificationService,
    private _router: Router,
    private _alert: AlertDialogService,
    private _modal: ModalService,
    //private readonly _jrs: JoyrideService,
    private observer: BreakpointObserver,
    private _http: HttpService,
    private _route: ActivatedRoute
  ) {
    // Fonction appelé à chaque changement de page
    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // vérification si mise à jour de l'application
        this._app.checkForUpdates();
        this._notifs.get();

        //this._http.cancelPendingRequests();    // Arrêt des appels de webservice en cours
        if (
          this._router.url.indexOf("/pages/incidents") != -1 ||
          this._router.url.indexOf("/pages/reg-prime") != -1
        ) {
          this.showSubmenu = true;
        } else {
          this.showSubmenu = false;
        }
      } else {
        return;
      }
      window.scrollTo(0, 0); // Scroll jusqu'en haut de la page
    });
  }

  public ngOnInit(): void {
    if (!this.oLogUser) {
      // Si pas connecté retour à la page Login
      this._router.navigate(["/"]);
    } else {
      this.fixedInViewport = true;
      this.mode = "side";
      this.opened = true;

      if (localStorage.getItem("Gesteam_Base") == null)
        localStorage.setItem(
          "Gesteam_Base",
          this._route.snapshot.paramMap.get("Base")
        );
      else {
        if (
          localStorage.getItem("Gesteam_Base") !=
          this._route.snapshot.paramMap.get("Base")
        ) {
          localStorage.removeItem("Gesteam_Base");
          localStorage.removeItem("Gesteam_Societe");
          localStorage.removeItem("Gesteam_ImageLogin");
          localStorage.removeItem("Gesteam_Login");
          this.logOutProcess();
        }
      }

      this.getInfosSociete();

      // Si guide de l'application jamais lancé, alors celui-ci apparait
      /*if (localStorage.getItem('Gesteam_Guide_Tour') !== 'O') {
        this.startTour();
      }*/

      // Chargement du thème choisi
      this.className = localStorage.getItem("Gesteam_Selected_Theme")
        ? localStorage.getItem("Gesteam_Selected_Theme")
        : "";

      // Vérification si l'utilisateur doit changer de mot de passe
      if (!this.oLogUser.login_Connect) {
        this._dialog.open(PasswordDialog, {
          width: "500px",
          disableClose: true,
        });
      }
    }
  }

  ngAfterViewInit(): void {
    this.observer.observe(["(max-width: 800px)"]).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = "over";
        this.sidenav.close();
      } else {
        this.sidenav.mode = "side";
        this.sidenav.open();
      }
    });
  }

  /*startTour(): void {
    const url = localStorage.getItem('Gesteam_Base') + '/pages/';
    this._router.navigateByUrl(url + 'planning');
    this._jrs.startTour({
      steps: [
        'st1', 'st2', 'st3', 'st4', 'st5', 'pl1@' + url + 'planning', 'pl2@' + url + 'planning', 'pl3@' + url + 'planning', 'pl4@' + url + 'planning',
        'pl5@' + url + 'planning', 'pl6@' + url + 'planning', 'pl7@' + url + 'planning', 'pl8@' + url + 'planning', 'pl9@' + url + 'planning',
        'pl10@' + url + 'planning', 'pl11@' + url + 'planning', 'pl15@' + url + 'planning', 'pl12@' + url + 'planning', 'pl13@' + url + 'planning',
        'pl14@' + url + 'planning', 'st6'
      ],
      themeColor: '#343434',
      showCounter: false,
      customTexts: {
        prev: 'Préc.',
        next: 'Suiv.',
        done: 'Compris !'
      }
    }).subscribe(
      (step) => { },
      (error) => { },
      () => {
        localStorage.setItem('Gesteam_Guide_Tour', 'O');
      }
    );
  }*/

  logOut(): void {
    this._alert.openDialog(
      "Confirmation",
      { message: "Etes-vous sûr de vouloir vous déconnecter ?" },
      "confirm",
      [
        {
          text: "Oui",
          type: "primary",
          callBack: () => {
            this.logOutProcess();
          },
        },
        {
          text: "Non",
          type: "warn",
          callBack: () => {},
        },
      ]
    );
  }

  logOutProcess(): void {
    sessionStorage.clear();
    localStorage.removeItem("Gesteam_Password");
    this._router.navigateByUrl(this._route.snapshot.paramMap.get("Base"));
  }

  openHelp(): void {
    this._router.navigateByUrl(localStorage.getItem("Gesteam_Base") + "/help");
  }

  toggleModal(id: string): void {
    if (
      this.modalAnimation == null ||
      this.modalAnimation === "slideOutToRight"
    ) {
      this._modal.open(id);
      this.modalAnimation = "slideInFromLeft";
    } else {
      this.closeModal(id, "slideOutToRight");
    }
  }

  openModal(id: string, anim: string): void {
    this._modal.open(id);
    this.modalAnimation = anim;
  }

  closeModal(id: string, anim: string): void {
    this.modalAnimation = anim;
    setTimeout(() => {
      this._modal.close(id);
    }, 500);
  }

  getPhoto(): string {
    if (this.oLogUser.login_Photo != "" && this.oLogUser.login_Photo != null) {
      return (
        "/" +
        localStorage.getItem("Gesteam_Base") +
        "/photo/" +
        this.oLogUser.login_Photo
      );
    } else {
      let avatarType = "male";
      if (
        this.oLogUser.login_Civilite == 1 ||
        this.oLogUser.login_Civilite == 2
      )
        avatarType = "female";
      return "/svg/avatar-" + avatarType + ".svg";
    }
  }

  showMenuLink(link: any): boolean {
    let show = true;

    if (link.bSofival && localStorage.getItem("Gesteam_Base") != "sofival") {
      show = false;
    }
    if (link.responsable && this.oLogUser.droits["RESPONSABLE"] != 1) {
      show = false;
    }
    if (
      link.validationIncident &&
      this.oLogUser.droits["VALIDATION_INCIDENT"] < 1 &&
      this.oLogUser.droits["VALIDATION_INCIDENT_SELF"] < 1
    ) {
      show = false;
    }

    return show;
  }

  getInfosSociete() {
    this._http.GET(
      "/api/Societe",
      null,
      (result) => {
        if (result.isOk) {
          sessionStorage.setItem("Gesteam_Societe", JSON.stringify(result.obj));
        }
      },
      (error) => {
        this._tools.showHttpError(error);
      }
    );
  }
}
