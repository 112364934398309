import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-notfound',
    templateUrl: './notfound.component.html',
    styleUrls: ['./notfound.component.scss'],
    standalone: false
})
export class NotfoundComponent {

  constructor(private _location: Location) { }

  goBack(): void {
    this._location.back();
  }

}
