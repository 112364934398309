import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from './alert-dialog';

@Injectable({ providedIn: 'root' })
export class AlertDialogService {

  constructor(private dialog: MatDialog) {}

  openDialog(title: string, message: any, type: string, buttons = []): void {
    this.dialog.open(AlertDialogComponent, {
      width: '600px',
      data: {
        dialogTitle: title,
        dialogMessage: message,
        dialogType: type,
        dialogButtons: buttons
      }
    });
  }

}
