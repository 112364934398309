import { Injectable } from "@angular/core";
import { AlertDialogService } from "./../../../components/_alert-dialog/alert-dialog.service";
import { HttpService } from "./../../../services/http.service";
import { ToolsService } from "./../../../services/tools.service";
import { SalDoc } from "./saldoc.class";

@Injectable()
export class DocumentsService {
  private oLogUser = JSON.parse(sessionStorage.getItem("Gesteam_User"));

  public docs: SalDoc[] = [];
  public docsFiltered: SalDoc[] = [];
  public load: boolean = false;

  constructor(
    private _http: HttpService,
    private _tools: ToolsService,
    private _alert: AlertDialogService
  ) {}

  async get() {
    this.load = true;
    this.oLogUser = JSON.parse(sessionStorage.getItem("Gesteam_User"));

    let salarie_ID = 0;
    if (
      this.oLogUser.droits["SALARIE_VISIBLE_SELF"] > 0 &&
      this.oLogUser.droits["SALARIE_VISIBLE"] <= 0
    )
      salarie_ID = this.oLogUser.login_ID;

    this._http.GET(
      `/api/SalDoc/${salarie_ID}`,
      null,
      (data) => {
        console.log("docs", data);
        if (data.isOk) {
          this.docs = data.obj;
          this.docsFiltered = data.obj;
        } else {
          this._alert.openDialog("Erreur", { message: data.message }, "error");
        }
        this.load = false;
      },
      (error) => {
        this._tools.showHttpError(error);
        this.load = false;
      }
    );
  }

  async post(doc: SalDoc) {
    this.load = true;

    this._http.POST(
      "/api/SalDoc",
      doc,
      (result) => {
        this.load = false;
        this.get();
      },
      (error) => {
        this._tools.showHttpError(error);
        this.load = false;
      }
    );
  }

  async delete(ID: number) {
    this.load = true;
    this._http.DELETE(
      "/api/SalDoc/" + ID,
      null,
      (result) => {
        if (result.isOk) {
          this._alert.openDialog(
            "Félicitation !",
            { message: "Le document a bien été supprimé" },
            "success"
          );
        } else {
          this._alert.openDialog(
            "Erreur",
            { message: result.message },
            "error"
          );
        }
        this.load = false;
        this.get();
      },
      (error) => {
        this._tools.showHttpError(error);
        this.load = false;
      }
    );
  }
}
