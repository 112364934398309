import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { NgScrollbarModule } from "ngx-scrollbar";
import { AppRouting } from "./app-routing";
import { ApplicationService } from "./services/application.service";

import { CommonModule, registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "./app.component";
import { AlertDialogService } from "./components/_alert-dialog/alert-dialog.service";
import { ModalModule } from "./components/_modal";
import { BottomSheetComponent } from "./core/bottom-sheet/bottom-sheet.component";
import { SpinnerModule } from "./core/spinner/spinner.module";
import { SvgProfilModule } from "./core/svg/svg-profil/svg-profil.module";
import { ForgotDialog } from "./login/forgot-dialog/forgot-dialog";
import { LoginComponent } from "./login/login.component";
import { FilterBasePipe, MainComponent } from "./main/main.component";
import { MaterialModule } from "./material-module";
import { NotfoundComponent } from "./notfound/notfound.component";
import { PagesComponent } from "./pages/pages.component";
import { ToolsService } from "./services/tools.service";

registerLocaleData(localeFr, "fr");

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PagesComponent,
    NotfoundComponent,
    ForgotDialog,
    MainComponent,
    FilterBasePipe,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRouting,
    SpinnerModule,
    BrowserAnimationsModule,
    MaterialModule,
    ModalModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-right",
      closeButton: true,
      progressBar: true,
      timeOut: 7000,
    }),
    NgxMatSelectSearchModule,
    SvgProfilModule,
    NgScrollbarModule,
  ],
  providers: [
    AlertDialogService,
    ToolsService,
    ApplicationService,
    BottomSheetComponent,
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
