<div class="loading" *ngIf="bLoad">
  <mat-spinner></mat-spinner>
</div>

<ng-container *ngIf="!bLoad">
  <div mat-dialog-content>
    <h4 class="important-title dark-txt">Mot de passe oublié ?</h4>
    <mat-divider></mat-divider><br/>
    <p>
      Veuillez renseigner votre adresse mail ci-dessous afin que nous puissions vous transmettre un nouveau mot de passe
    </p>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Email</mat-label>
      <input matInput type="email" [(ngModel)]="sEmail">
      <mat-icon matSuffix>alternate_email</mat-icon>
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end" class="dialog-footer-buttons third-background">
    <button color="primary" mat-stroked-button (click)="onClick()">Valider</button>
    <button color="warn" mat-stroked-button (click)="onNoClick()" cdkFocusInitial>Annuler</button>
  </div>
</ng-container>
