import { AlertDialogService } from './../components/_alert-dialog/alert-dialog.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

interface Main {
  base_Path: string,
  base_Extranet: string,
  base_Logo: string,
  base_ImageLogin: string
};

@Pipe({
  name: 'filterBase'
})
export class FilterBasePipe implements PipeTransform {
  transform(items: any[], term: string): any[] {
    return items.filter(item => item.base_Path.toLowerCase().includes(term.toLowerCase()));
  }
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  private base = localStorage.getItem('Gesteam_Base');
  public bases: Main[];
  public bLoad: boolean;
  public sSearch: string = '';
  public version: string = localStorage.getItem('Gesteam_Version');

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _alert: AlertDialogService) { }

  ngOnInit(): void {
    if (this.base !== null) {
      this._router.navigate([this.base]);
    } else {
      this.getAgences();
    }
  }

  getAgences(): void {
    this.bLoad = true;
    this.bases = [];

    const header = new HttpHeaders({'Content-Type': 'application/json'});
    const httpOptions = { headers: header };

    this._http.get<any>('/api/Main', httpOptions).subscribe(
      result => {
        console.log(result);
        if (result.isOk) {
          this.bases = result.obj;
          if (this.bases.length === 1) {    // Il n'y a qu'une seule base
            localStorage.setItem('Gesteam_Base', this.bases[0].base_Extranet);
            this._router.navigate([this.bases[0].base_Extranet]);
          }
        }
        this.bLoad = false;
      },
      error => {
        this.bLoad = false;
        this._alert.openDialog('Erreur', error.message, 'error');
      }
    );
  }

  connect(b: Main): void {
    if (b != undefined) {
      localStorage.setItem('Gesteam_Base', b.base_Extranet);
      localStorage.setItem('Gesteam_Logo', b.base_Logo);
      localStorage.setItem('Gesteam_Societe', b.base_Path);
      localStorage.setItem('Gesteam_ImageLogin', b.base_ImageLogin);
      this._router.navigate([b.base_Extranet]);
    }
  }

  setImageLogin() {
    return {
      'background': 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/img/login-background.jpg)'
    };
  }

  setImageAgence(b: Main) {
    if (b != undefined) {
      if (b.base_ImageLogin != null) {
        return {
          'background': 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/img/backgrounds/' + b.base_ImageLogin + ')'
        };
      } else {
        return {
          'background': 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/img/login-background.jpg)'
        };
      }
    } else {
      return {
        'background': 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/img/login-background.jpg)'
      };
    }
  }

}
