import { OverlayContainer } from "@angular/cdk/overlay";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { ModalService } from "./components/_modal";
import { routeAppAnimation, slideInAnimation } from "./exports/animation";
import { ApplicationService } from "./services/application.service";
import { ThemeService } from "./services/theme.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  animations: [slideInAnimation, routeAppAnimation],
  standalone: false,
})
export class AppComponent implements OnInit {
  static AlertTitle: String;
  static AlertMessage: String;
  static AlertType: String;
  static AlertAnimation: string;
  static AlertButtons: any;
  static AlertErrorList: string[];
  imgs = new Array();

  constructor(
    private _modal: ModalService,
    private _overlay: OverlayContainer,
    private _app: ApplicationService,
    private _theme: ThemeService,
    private _http: HttpClient,
    public _ls: LoadingBarService
  ) {
    console.log("ls", _ls);
  }

  ngOnInit(): void {
    this._app.checkForUpdates();
    this._theme.checkActiveDarkTheme();
    this.checkSocieteInfo();
    if (localStorage.getItem("Gesteam_Theme")) {
      this._theme.addTheme(localStorage.getItem("Gesteam_Theme"));
    }
  }

  closeAlert(id: string): void {
    AppComponent.AlertAnimation = "jumpUp";
    setTimeout(() => {
      this._modal.close(id);
    }, 500);
  }

  callFunction(f: Function): void {
    f();
    this.closeAlert("confirm-modal");
  }

  checkSocieteInfo(): void {
    const base = window.location.href.split("/")[3];
    if (
      base != null &&
      (localStorage.getItem("Gesteam_Societe") == null ||
        localStorage.getItem("Gesteam_Logo") == null ||
        localStorage.getItem("Gesteam_ImageLogin") == null ||
        localStorage.getItem("Gesteam_Published") == null ||
        base != localStorage.getItem("Gesteam_Base"))
    ) {
      const header = new HttpHeaders({ "Content-Type": "application/json" });
      const httpOptions = { headers: header };

      this._http
        .get<any>("/api/Main/" + base, httpOptions)
        .subscribe((result) => {
          if (result.isOk) {
            console.log("result theme", result);
            if (result.obj.length > 0) {
              localStorage.setItem("Gesteam_Base", result.obj[0].base_Extranet);
              localStorage.setItem(
                "Gesteam_Theme",
                result.obj[0].base_Extranet
              );
              localStorage.setItem("Gesteam_Logo", result.obj[0].base_Logo);
              localStorage.setItem("Gesteam_Societe", result.obj[0].base_Path);
              localStorage.setItem(
                "Gesteam_ImageLogin",
                result.obj[0].base_ImageLogin
              );
              localStorage.setItem(
                "Gesteam_Published",
                result.obj[0].base_Published
              );

              location.reload();
            }
          }
        });
    }
  }

  get getAlertTitle() {
    return AppComponent.AlertTitle;
  }
  get getAlertMessage() {
    return AppComponent.AlertMessage;
  }
  get getAlertAnimation() {
    return AppComponent.AlertAnimation;
  }
  get getAlertButtons() {
    return AppComponent.AlertButtons;
  }
  get getAlertErrorList() {
    return AppComponent.AlertErrorList;
  }
  get bSuccess() {
    return AppComponent.AlertType == "success";
  }
  get bError() {
    return AppComponent.AlertType == "error";
  }
}
