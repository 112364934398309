import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Exercice } from "../../../modules/_exercices/interface";
import { Periode } from "../../../modules/_periodes/interface";
import { HttpService } from "../../../services/http.service";
import { ToolsService } from "../../../services/tools.service";
import { AlertDialogService } from "./../../../components/_alert-dialog/alert-dialog.service";

@Injectable({
  providedIn: "root",
})
export class CompteursService {
  private oLogUser = JSON.parse(sessionStorage.getItem("Gesteam_User"));
  private httpRequest: any = null;
  private httpRequestSal: any = null;

  public selectedExercice: Exercice = null;
  public selectedPeriode: Periode = null;
  public departement_ID: number = 0;
  public service_ID: number = 0;
  public installation_ID: number = 0;
  public salarie_ID: number = 0;
  public year: number = 0;
  public periode: number = 0;
  public events: any = [];
  public primes: any = [];
  public load: boolean = false;
  public sMessage: string = "";
  public startDate: Date = null;
  public endDate: Date = null;
  public bShowLoad: boolean = false;
  public bFinishLoad: boolean = false;

  constructor(
    private _http: HttpClient,
    private _tools: ToolsService,
    private _alert: AlertDialogService,
    private _httpServices: HttpService
  ) {}

  async gen() {
    this.load = true;
    this.bShowLoad = true;
    this.bFinishLoad = false;
    this.sMessage = "Génération des compteurs en cours ...";
    this.oLogUser = JSON.parse(sessionStorage.getItem("Gesteam_User"));

    if (this.salarie_ID == 0 && this.selectedPeriode != null) {
      this.startDate = new Date(this.selectedPeriode.dateDebut);
      this.endDate = new Date(this.selectedPeriode.dateFin);
    }

    if (this.salarie_ID != 0 && this.selectedExercice != null) {
      this.startDate = new Date(this.selectedExercice.exercice_Debut);
      this.endDate = new Date(this.selectedExercice.exercice_Fin);
    }

    const header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.oLogUser.login_Token,
    });
    const params = {
      Salarie_ID:
        this.oLogUser.droits["RESPONSABLE"] == 0
          ? this.oLogUser.login_ID
          : this.salarie_ID,
      Secteur_ID: this.salarie_ID == 0 ? this.service_ID : 0,
      Installation_ID: this.installation_ID == 0 ? this.installation_ID : 0,
      Departement_ID: this.departement_ID,
      Login_ID: this.oLogUser.login_ID,
      Debut: this._tools.dateToString(this.startDate),
      Fin: this._tools.dateToString(this.endDate),
    };
    const httpOptions = { headers: header };

    console.log("paramsGen", params);

    this.httpRequest = this._http
      .post(
        localStorage.getItem("Gesteam_Base") + "/api/Compteur/GenCompteur",
        params,
        httpOptions
      )
      .subscribe(
        (result: any) => {
          if (result.isOk) {
            this.get();
          } else {
            this._alert.openDialog(
              "Erreur",
              { message: result.message },
              "error"
            );
            this.load = false;
            this.bShowLoad = false;
          }
        },
        (error) => {
          this._tools.showHttpError(error);
          this.load = false;
          this.bShowLoad = false;
        }
      );
  }

  async genForSalarie(
    salarie_ID: number,
    login_ID: number,
    start: Date,
    end: Date
  ) {
    this.load = true;
    this.bShowLoad = true;
    this.bFinishLoad = false;
    this.sMessage = "Génération des compteurs en cours ...";
    this.oLogUser = JSON.parse(sessionStorage.getItem("Gesteam_User"));

    const header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.oLogUser.login_Token,
    });
    const params = {
      Salarie_ID: salarie_ID,
      Secteur_ID: 0,
      Installation_ID: 0,
      Departement_ID: 0,
      Login_ID: login_ID,
      Debut: this._tools.dateToString(start),
      Fin: this._tools.dateToString(end),
    };
    const httpOptions = { headers: header };

    console.log("paramsGen", params);

    this.httpRequestSal = this._http
      .post(
        localStorage.getItem("Gesteam_Base") + "/api/Compteur/GenCompteur",
        params,
        httpOptions
      )
      .subscribe(
        (result: any) => {
          if (result.isOk) {
            this.getSal(salarie_ID, start, end);
          } else {
            this._alert.openDialog(
              "Erreur",
              { message: result.message },
              "error"
            );
            this.load = false;
            this.bShowLoad = false;
          }
        },
        (error) => {
          this._tools.showHttpError(error);
          this.load = false;
          this.bShowLoad = false;
        }
      );
  }

  async get() {
    this.events = [];
    this.primes = [];
    this.oLogUser = JSON.parse(sessionStorage.getItem("Gesteam_User"));

    // Enregistrement des paramètres utilisés dans le storage
    sessionStorage.setItem(
      "Gesteam_Compteur_Filter_Departement",
      this.departement_ID.toString()
    );
    sessionStorage.setItem(
      "Gesteam_Compteur_Filter_Service",
      this.service_ID.toString()
    );
    sessionStorage.setItem(
      "Gesteam_Compteur_Filter_Installation",
      this.installation_ID.toString()
    );
    sessionStorage.setItem(
      "Gesteam_Compteur_Filter_Exercice",
      this.year.toString()
    );
    sessionStorage.setItem(
      "Gesteam_Compteur_Filter_Periode",
      this.periode.toString()
    );
    sessionStorage.setItem(
      "Gesteam_Compteur_Filter_Salarie",
      this.salarie_ID.toString()
    );
    sessionStorage.setItem(
      "Gesteam_Compteur_Filter_SelectedPeriode",
      JSON.stringify(this.selectedPeriode)
    );
    sessionStorage.setItem(
      "Gesteam_Compteur_Filter_SelectedExercice",
      JSON.stringify(this.selectedExercice)
    );

    // Il faut au moins choisir un service, un exercice et un salarié ou une période
    if (this.year != 0 && (this.salarie_ID != 0 || this.periode != 0)) {
      this.load = true;
      this.bShowLoad = true;
      this.bFinishLoad = false;
      this.sMessage = "Récupération des compteurs en cours ...";

      if (this.salarie_ID == 0 && this.selectedPeriode != null) {
        this.startDate = new Date(this.selectedPeriode.dateDebut);
        this.endDate = new Date(this.selectedPeriode.dateFin);
      }

      if (this.salarie_ID != 0 && this.selectedExercice != null) {
        this.startDate = new Date(this.selectedExercice.exercice_Debut);
        this.endDate = new Date(this.selectedExercice.exercice_Fin);
      }

      const header = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.oLogUser.login_Token,
      });
      const params = {
        Debut: this._tools.dateToString(this.startDate),
        Fin: this._tools.dateToString(this.endDate),
        Service_ID: this.service_ID,
        Salarie_ID:
          this.oLogUser.droits["RESPONSABLE"] == 0
            ? this.oLogUser.login_ID
            : this.salarie_ID,
        Departement_ID: this.departement_ID,
      };
      const httpOptions = { headers: header, params: params };

      this._httpServices.currentURL = "/api/Compteur";

      console.log("paramsGet", params);

      this._http
        .get(
          localStorage.getItem("Gesteam_Base") + "/api/Compteur",
          httpOptions
        )
        .subscribe(
          (result: any) => {
            console.log("GETCOMPTEURS :");
            console.log(result);
            if (result.isOk) {
              this.events = result.obj.myEvents;
              this.primes = result.obj.myPrimes;
              this.bFinishLoad = true;
            } else {
              this._alert.openDialog(
                "Erreur",
                { message: result.message },
                "error"
              );
              this.bShowLoad = false;
            }
            this.load = false;
          },
          (error) => {
            this._tools.showHttpError(error);
            this.load = false;
            this.bShowLoad = false;
          }
        );
    }
  }

  async getSal(salarie_ID: number, start: Date, end: Date) {
    this.events = [];
    this.primes = [];
    this.oLogUser = JSON.parse(sessionStorage.getItem("Gesteam_User"));

    this.load = true;
    this.bShowLoad = true;
    this.bFinishLoad = false;
    this.sMessage = "Récupération des compteurs en cours ...";

    const header = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.oLogUser.login_Token,
    });
    const params = {
      Debut: this._tools.dateToString(start),
      Fin: this._tools.dateToString(end),
      Service_ID: 0,
      Salarie_ID: salarie_ID,
      Departement_ID: 0,
    };
    const httpOptions = { headers: header, params: params };

    this._httpServices.currentURL = "/api/Compteur";

    console.log("paramsGet", params);

    this._http
      .get(localStorage.getItem("Gesteam_Base") + "/api/Compteur", httpOptions)
      .subscribe(
        (result: any) => {
          console.log("GETCOMPTEURS :");
          console.log(result);
          if (result.isOk) {
            this.events = result.obj.myEvents;
            this.primes = result.obj.myPrimes;
            this.bFinishLoad = true;
          } else {
            this._alert.openDialog(
              "Erreur",
              { message: result.message },
              "error"
            );
            this.bShowLoad = false;
          }
          this.load = false;
        },
        (error) => {
          this._tools.showHttpError(error);
          this.load = false;
          this.bShowLoad = false;
        }
      );
  }

  cancelRequest() {
    if (this.httpRequest != null) {
      this.httpRequest.unsubscribe();
      this.httpRequest = null;
    }
    if (this.httpRequestSal != null) {
      this.httpRequestSal.unsubscribe();
      this.httpRequestSal = null;
    }
    this.load = false;
    this.bShowLoad = false;
    this.bFinishLoad = false;
  }
}
