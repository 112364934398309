<!-- <ngx-loading-bar [includeSpinner]="false" height="3px"></ngx-loading-bar> -->
<mat-progress-bar
  *ngIf="_ls.progress$ | async as progress"
  mode="indeterminate"
  [value]="progress"
  class="h-[3px]"
></mat-progress-bar>
<div [@routeAppAnimation]="o.isActivated ? o.activatedRoute : ''">
  <router-outlet #o="outlet" class="mat-app-background">
    <app-spinner></app-spinner>
  </router-outlet>
</div>
