import { MainComponent } from './main/main.component';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { NotfoundComponent } from './notfound/notfound.component';

const routes: Routes = [
  {
    path: ':Base/pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: '',
    component: MainComponent,
    pathMatch: 'full'
  },
  {
    path: ':Base',
    component: LoginComponent
  },
  {
    path: ':Base/login',
    component: LoginComponent
  },
  {
    path: '404/notfound',
    component: NotfoundComponent
  },
  { path: '**', redirectTo: '404/notfound' },
];

const config: ExtraOptions = {
    useHash: false
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRouting { }
