import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DocumentsService } from "../pages/divers/documents/documents.service";
import { SalDoc } from "../pages/divers/documents/saldoc.class";
import { IncidentsService } from "./../pages/planification/incidents/incidents.service";

@Injectable()
export class NotificationService {
  public oLogUser = JSON.parse(sessionStorage.getItem("Gesteam_User"));

  constructor(
    private _incidents: IncidentsService,
    private _documents: DocumentsService
  ) {}

  get() {
    this._incidents.get(
      this.oLogUser.service_ID,
      this.oLogUser.installation_ID,
      "",
      "",
      0
    );
    this._documents.get();
  }

  getNewIncidents(): Observable<any[]> {
    return this._incidents.incidents$;
  }

  getNewDocuments(): SalDoc[] {
    return this._documents.docs.filter(
      (element: SalDoc) => element.salDoc_Statut == 0
    );
  }
}
