import { Component, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
  selector: 'app-spinner',
  template: `
    <div class="preloader dark-background" *ngIf="isSpinnerVisible">
        <div class="spinner">
          <div class="double-bounce1 bg-primary"></div>
          <div class="double-bounce2 bg-primary"></div>
        </div>
    </div>
  `,
  styleUrls: ['spinner.component.scss']
})
export class SpinnerComponent implements OnDestroy {
  public isSpinnerVisible = true;

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          //this.isSpinnerVisible = true;
        }
        else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.hideSpinner();
        }
      },
      () => {
        this.hideSpinner();
      }
    );
  }

  ngOnDestroy(): void {
    this.hideSpinner();
  }

  hideSpinner(): void {
    setTimeout(() => {
      this.isSpinnerVisible = false;
    }, 1000);
  }
}
