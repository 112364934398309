<div class="full-page darkMode" [ngStyle]="$any(setImageLogin())">
  <div class="base-container main-content max-960 m-auto" style="margin-top: 3%">
    <div class="logo-zone">
      <img src="/img/logo-brand.png" alt="">
    </div>

    <div class="spacer-16"></div>

    <mat-form-field appearance="outline" class="full-width" style="color: white;" color="accent">
      <mat-label>Rechercher</mat-label>
      <input matInput name="searchSoc" [(ngModel)]="sSearch">
      <mat-icon matPrefix color="accent">search</mat-icon>
    </mat-form-field>

    <div class="grid gap-16 col-2 agence-list">
      <mat-card appearance="outlined" *ngFor="let b of bases | filterBase: sSearch;" class="full border border-color no-padding" [ngStyle]="$any(setImageAgence(b))">
        <div class="flex">
          <div class="img-agence">
            <img src="/img/logos/{{b.base_Logo}}" alt="">
          </div>
          <div class="margin-16 agence-content dark-txt">
            {{b.base_Path}}<br/>
            <button mat-stroked-button (click)="connect(b)">
              Se connecter <mat-icon iconPositionEnd>forward</mat-icon>
            </button>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="version dark-txt">
      <div class="spacer-16"></div>
      &copy; <a href="https://hpdg.fr/" class="secondary" target="_blank">HPDG</a><span *ngIf="version"> - Version {{version}}</span>
    </div>
  </div>
</div>
