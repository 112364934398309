import { AlertDialogService } from './../components/_alert-dialog/alert-dialog.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ForgotDialog } from './forgot-dialog/forgot-dialog';
import { ApplicationService } from '../services/application.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm = new UntypedFormGroup({
    user: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
    remember: new UntypedFormControl(false)
  });
  public bConnect = false;
  public hide = true;

  public logo = localStorage.getItem('Gesteam_Logo');
  public societe = localStorage.getItem('Gesteam_Societe');
  public imageLogin = localStorage.getItem('Gesteam_ImageLogin');
  public theme = localStorage.getItem('Gesteam_Selected_Theme');

  constructor(
    public _dialog: MatDialog,
    public _app: ApplicationService,
    private _http: HttpClient,
    private _router: Router,
    private _route: ActivatedRoute,
    private _alert: AlertDialogService
  ) { }

  ngOnInit(): void {
    this.checkSocieteInfo();
    console.log(localStorage.getItem('Gesteam_Login'))
    if (localStorage.getItem('Gesteam_Login') != null) {
      this.loginForm.get('user').setValue(localStorage.getItem('Gesteam_Login'));
    }

    if (localStorage.getItem('Gesteam_Password') != null) {
      this.loginForm.get('password').setValue(localStorage.getItem('Gesteam_Password'));
    }

    if (localStorage.getItem('Gesteam_Remember') == 'O') {
      this.loginForm.get('remember').setValue(true);
    }

    if (localStorage.getItem('Gesteam_Remember') == 'O' && localStorage.getItem('Gesteam_Password') != null) {
      this.connect(this.loginForm);
    }
  }

  connect(log: UntypedFormGroup): void {
    if (log.status === 'VALID') {
      this.bConnect = true;

      const params = {
        User: encodeURIComponent(log.value.user),
        Password: encodeURIComponent(log.value.password)
      };
      const header = new HttpHeaders({'Content-Type': 'application/json'});
      const httpOptions = { headers: header, params: params };
      this._http.get<any>(this._route.snapshot.paramMap.get('Base') + '/api/Connect', httpOptions).subscribe(
        result => {
          if (result.isOk) {
            sessionStorage.setItem('Gesteam_User', JSON.stringify(result.obj));

            localStorage.setItem('Gesteam_Login', log.value.user);
            if (log.value.remember) {
              localStorage.setItem('Gesteam_Remember', 'O');
              localStorage.setItem('Gesteam_Password', log.value.password);
            }

            this._router.navigateByUrl(this._route.snapshot.paramMap.get('Base') + '/pages');
          } else {
            this._alert.openDialog('Erreur', {message: result.message}, 'error');
            this.bConnect = false;
          }
        },
        error => {
          console.error(error);
          this._alert.openDialog('Erreur', 'Impossible de vous connecter ...', 'error');
          this.bConnect = false;
        }
      );

      /*this._http.post<any>('/api/Connect/GetConnect',
        {
          User: log.value.user,
          Password: log.value.password
        }
      ).subscribe(result => {
        if (result.isOk) {
          sessionStorage.setItem('Gesteam_User', JSON.stringify(result.obj));

          localStorage.setItem('Gesteam_Login', log.value.user);
          if (log.value.remember) {
            localStorage.setItem('Gesteam_Remember', 'O');
            localStorage.setItem('Gesteam_Password', log.value.password);
          }

          this._router.navigateByUrl('/pages');
        } else {
          this._alert.openDialog('Erreur', {message: result.message}, 'error');
          this.bConnect = false;
        }
      }, error => {
        console.error(error);
        this.bConnect = false;
      });*/
    }
  }

  hasError(form: UntypedFormGroup, controlName: string, errorName: string) {
    return form.controls[controlName].hasError(errorName);
  }

  openForgotDialog(): void {
    this._dialog.open(ForgotDialog, {
      width: '500px'
    });
  }

  changeBase(): void {
    this.bConnect = true;
    localStorage.removeItem('Gesteam_Base');
    localStorage.removeItem('Gesteam_Login');
    localStorage.removeItem('Gesteam_Password');
    localStorage.removeItem('Gesteam_Remember');
    localStorage.removeItem('Gesteam_Logo');
    localStorage.removeItem('Gesteam_Societe');
    localStorage.removeItem('Gesteam_ImageLogin');
    this._router.navigate(['/']);
    // window.location.href = '/';
  }

  setImageLogin() {
    if (this.imageLogin != null) {
      return {
        'background': 'url(/img/backgrounds/' + this.imageLogin + ')'
      };
    } else {
      return {
        'background': 'url(/img/login-background.jpg)'
      };
    }
  }

  checkSocieteInfo(): void {
    if (this._route.snapshot.paramMap.get('Base') != null
      && ((localStorage.getItem('Gesteam_Societe') == null
      || localStorage.getItem('Gesteam_Logo') == null
      || localStorage.getItem('Gesteam_ImageLogin') == null)
      || this._route.snapshot.paramMap.get('Base') != localStorage.getItem('Gesteam_Base'))) {

      const header = new HttpHeaders({'Content-Type': 'application/json'});
      const httpOptions = { headers: header };

      this._http.get<any>('/api/Main/' + this._route.snapshot.paramMap.get('Base'), httpOptions).subscribe(
        result => {
          if (result.isOk) {
            if (result.obj.length > 0) {
              localStorage.setItem('Gesteam_Base', result.obj[0].base_Extranet);
              localStorage.setItem('Gesteam_Logo', result.obj[0].base_Logo);
              localStorage.setItem('Gesteam_Societe', result.obj[0].base_Path);
              localStorage.setItem('Gesteam_ImageLogin', result.obj[0].base_ImageLogin);

              this.logo = localStorage.getItem('Gesteam_Logo');
              this.societe = localStorage.getItem('Gesteam_Societe');
              this.imageLogin = localStorage.getItem('Gesteam_ImageLogin');
            } else {
              this.changeBase();
            }
          } else {
            this.changeBase();
          }
        },
        error => {
          this._alert.openDialog('Erreur', error.message, 'error');
        }
      );
    }
  }
}
