import { IncidentsService } from './../pages/planification/incidents/incidents.service';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class NotificationService {

  constructor(
    private _incidents: IncidentsService
  ) { }

  get() {
    this._incidents.get(0, '', '', 0);
  }

  getNewIncidents(): Observable<any[]> {
    return this._incidents.incidents$;
  }

}
