import { Component, Input } from '@angular/core';

@Component({
  selector: 'svg-profil',
  templateUrl: './svg-profil.component.html',
  styleUrls: ['../svg.scss']
})
export class SvgProfilComponent {

  @Input() civilite: number;

  constructor() { }

}
