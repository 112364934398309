import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable()
export class HttpService {

  private oLogUser = JSON.parse(sessionStorage.getItem('Gesteam_User'));
  // protected ngUnsubscribe: Subject<void> = new Subject<void>();
  private pendingHTTPRequests$ = new Subject<void>();

  public currentURL: string = '';

  constructor(private _http: HttpClient) { }

  GET(sUrl: string, params: any, callBackSuccess: Function, callBackError: Function) {
    this.oLogUser = JSON.parse(sessionStorage.getItem('Gesteam_User'));
    const header = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.oLogUser.login_Token});
    let httpOptions;
    if (params != null) {
      httpOptions = { headers: header, params: params };
    } else {
      httpOptions = { headers: header };
    }

    this.currentURL = sUrl;

    this._http.get(localStorage.getItem('Gesteam_Base') + sUrl, httpOptions)
      .subscribe(
        result => {
          callBackSuccess(result);
        },
        error => {
          callBackError(error);
        }
      );
  }

  POST(sUrl: string, params: any, callBackSuccess: Function, callBackError: Function) {
    this.oLogUser = JSON.parse(sessionStorage.getItem('Gesteam_User'));
    const header = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.oLogUser.login_Token});
    const httpOptions = { headers: header };

    this.currentURL = sUrl;

    this._http.post(localStorage.getItem('Gesteam_Base') + sUrl, params, httpOptions)
      .subscribe(
        result => {
          callBackSuccess(result);
        },
        error => {
          callBackError(error);
        }
      );
  }

  DELETE(sUrl: string, params: any, callBackSuccess: Function, callBackError: Function) {
    this.oLogUser = JSON.parse(sessionStorage.getItem('Gesteam_User'));
    const header = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.oLogUser.login_Token});
    let httpOptions;
    if (params != null) {
      httpOptions = { headers: header, params: params };
    } else {
      httpOptions = { headers: header };
    }

    this.currentURL = sUrl;

    this._http.delete(localStorage.getItem('Gesteam_Base') + sUrl, httpOptions)
      .subscribe(
        result => {
          callBackSuccess(result);
        },
        error => {
          callBackError(error);
        }
      );
  }

  cancelPendingRequests() {
    this.pendingHTTPRequests$.next();
  }

  onCancelPendingRequests() {
    return this.pendingHTTPRequests$.asObservable();
  }

}
