import { Component, HostBinding, OnInit } from '@angular/core';
import { ModalService } from './components/_modal';
import { routeAppAnimation, slideInAnimation } from './exports/animation';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ApplicationService } from './services/application.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [ slideInAnimation, routeAppAnimation ]
})

export class AppComponent implements OnInit {

  static AlertTitle: String;
  static AlertMessage: String;
  static AlertType: String;
  static AlertAnimation: string;
  static AlertButtons: any;
  static AlertErrorList: string[];
  imgs = new Array();

  @HostBinding('class') className = '';

  constructor(
    private _modal: ModalService,
    private _overlay: OverlayContainer,
    private _app: ApplicationService
  ) { }

  ngOnInit(): void {
    this._app.checkForUpdates();
    this.className = this.checkActiveDarkTheme() ? 'darkMode' : '';
    this._overlay.getContainerElement().classList.add(this.className);
    /*this.pload(
      "/img/backgrounds/background-menu.jpg"
    );*/
  }

  closeAlert(id: string): void {
    AppComponent.AlertAnimation = 'jumpUp';
    setTimeout(() => {
      this._modal.close(id);
    }, 500);
  }

  callFunction(f: Function): void {
    f();
    this.closeAlert('confirm-modal');
  }

  checkActiveDarkTheme(): boolean {
    if (localStorage.getItem('Gesteam_Selected_Theme') == 'darkMode') {
      return true;
    } else {
      try {
        const splitDebut = localStorage.getItem('Gesteam_DarkTheme_Debut').split(':');
        const splitFin = localStorage.getItem('Gesteam_DarkTheme_Fin').split(':');
        const debut = new Date(new Date().setHours(parseInt(splitDebut[0]), parseInt(splitDebut[1]), 0, 0));
        let fin = new Date(new Date().setHours(parseInt(splitFin[0]), parseInt(splitFin[1]), 0, 0));
        if (fin.getTime() < debut.getTime()) {
          fin.setDate(fin.getDate() + 1);
        }

        if (new Date() >= debut && new Date() <= fin) {
          return true;
        } else {
          return false;
        }
      } catch (ex) {
        return false;
      }
    }
  }

  pload(...args: any[]):void {
    for (var i = 0; i < args.length; i++) {
      this.imgs[i] = new Image();
      this.imgs[i].src = args[i];
      console.log('loaded: ' + args[i]);
    }
  }

  get getAlertTitle() { return AppComponent.AlertTitle; }
  get getAlertMessage() { return AppComponent.AlertMessage; }
  get getAlertAnimation() { return AppComponent.AlertAnimation; }
  get getAlertButtons() { return AppComponent.AlertButtons; }
  get getAlertErrorList() { return AppComponent.AlertErrorList; }
  get bSuccess() { return AppComponent.AlertType == 'success'; }
  get bError() { return AppComponent.AlertType == 'error'; }
}
